<template>
    <section>
        <section class="search-container">
            <a-form layout="inline" class="m-t-14">
                <a-form-item label="里程碑">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.milestoneName" />
                </a-form-item>
                <a-form-item label="子里程碑">
                    <a-input class="search-input" placeholder="请输入" v-model="searchInfo.sonName" />
                </a-form-item>
                
                <a-form-item label="任务状态">
                    <a-select class="search-select" placeholder="全部" v-model="searchInfo.status">
                        <a-select-option value="">全部</a-select-option>
                        <a-select-option :value="1">待审核</a-select-option>
                        <a-select-option :value="2">审核中</a-select-option>
                        <a-select-option :value="3">已通过</a-select-option>
                        <a-select-option :value="4">已驳回</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="发起时间">
                    <a-date-picker class="search-select" placeholder="请选择" v-model="searchInfo.submitTime" />
                </a-form-item>
                <a-form-item>
                    <a-button class="m-r-8" type="primary" @click="search"><a-icon type="search" />查询</a-button>
                    <a-button @click="reset"><a-icon type="reload" />重置</a-button>
                </a-form-item>
            </a-form>
        </section>
        <section class="list-table-container m-t-18">
            <a-table size="small" :columns="columns" :data-source="listMap" :pagination="{total,hideOnSinglePage:true,showTotal:(total) => `共${total}条`,size:'default',showQuickJumper:true,showSizeChanger:false}" @change="changePageNum">
                 <span slot="actualDay" :style="{color:text > 0 ? '#52C41A' : text  == 0 ? '#1890ff' : '#FF555E'}" slot-scope="text">{{text > 0 ?  ('提前'+text+'天') : text == 0 ? '无' : ('滞后'+ Math.abs(text) +'天')}}</span>
                 <!-- 状态 -->
                <span slot="status" slot-scope="text">{{text === 1 ? '待审核' : text === 2 ? '审核中' : text === 3 ? '已通过' : '已驳回'}}</span>
                <!-- 操作 -->
                <a slot="action" slot-scope="text,list">
                    <a @click="showDrawerModal(list)">查看</a>
                </a>
            </a-table>
        </section>
        <a-drawer
        class="drawer_module"
        :title="drawerModal.title"
        placement="right"
        :visible="drawerModal.visible"
        :width="360"
        :destroyOnClose="true"
        @close="drawerModal.visible = false"
        >
        
        <a-row class="info-container">
            <section class="info-item-wrap">
                <a-row class="info-item" type="flex">
                    <a-col class="key">里程碑</a-col>
                    <a-col class="val">{{itemInfo.milestoneName}}</a-col>
                </a-row>
                <a-row class="info-item" type="flex">
                    <a-col class="key">子里程碑</a-col>
                    <a-col class="val">{{itemInfo.sonName}}</a-col>
                </a-row>
                <a-row class="info-item" type="flex">
                    <a-col class="key">计划开始</a-col>
                    <a-col class="val">{{itemInfo.planStartDate}}</a-col>
                </a-row>
                <a-row class="info-item" type="flex">
                    <a-col class="key">计划完成</a-col>
                    <a-col class="val">{{itemInfo.planEndDate}}</a-col>
                </a-row>
                <a-row class="info-item" type="flex">
                    <a-col class="key">实际开始</a-col>
                    <a-col class="val">{{itemInfo.actualStartDate}}</a-col>
                </a-row>
                <a-row class="info-item" type="flex">
                    <a-col class="key">实际完成</a-col>
                    <a-col class="val">{{itemInfo.actualEndDate}}</a-col>
                </a-row>
                <a-row class="info-item" type="flex">
                    <a-col class="key">偏差天数</a-col>
                    <a-col class="val" :style="{color:itemInfo.actualDay > 0 ? '#52C41A' : itemInfo.actualDay  == 0 ? '#1890ff' : '#FF555E'}">{{itemInfo.actualDay > 0 ?  ('提前'+itemInfo.actualDay+'天') : itemInfo.actualDay == 0 ? '无' : ('滞后'+ Math.abs(itemInfo.actualDay) +'天')}}</a-col>
                </a-row>

                <a-form :form="form"  layout="inline" v-if="(itemInfo.status == 1 || itemInfo.status == 2) && itemInfo.acceptanceUser === userId">
                    <a-row style="width:100%" class="info-item">
                        <a-form-item>
                            <span class="key" slot="label">验收意见</span>
                            <a-row style="padding-left:40px">
                            <a-radio-group v-decorator="['status',{rules:[{required:false}]}]">
                                <a-radio :value="3">通过</a-radio>
                                <a-radio :value="4">驳回</a-radio>
                            </a-radio-group>
                            </a-row>
                        </a-form-item>
                    </a-row>
                    <a-row class="info-item">
                        <a-form-item>
                            <span class="key" slot="label">验收备注</span>
                            <a-row style="padding-left:40px">
                                <a-input placeholder="备注" v-decorator="['remark',{rules:[{required:false}]}]" />
                            </a-row>
                        </a-form-item>
                    </a-row>
                    <a-row class="form-btn-group">
                        <a-button @click="submit" style="margin-left:110px">提交</a-button>
                        <a-button @click="drawerModal.visible = false">取消</a-button>
                    </a-row>
                </a-form>
            </section>
        </a-row>
    </a-drawer>
    </section>
</template>

<script>
const columns = [
    {title:'序号',dataIndex:'key',align:'center',key:'key'},
    {title:'里程碑',dataIndex:'milestoneName',align:'center',key:'milestoneName'},
    {title:'子里程碑',dataIndex:'sonName',align:'center',key:'sonName'},
    {title:'计划完成',dataIndex:'planEndDate',align:'center',key:'planEndDate'},
    {title:'实际完成',dataIndex:'actualEndDate',align:'center',key:'actualEndDate'},
    {title:'偏差天数',dataIndex:'actualDay',align:'center',key:'actualDay',scopedSlots:{customRender: "actualDay"}},
    {title:'发起时间',dataIndex:'submitTime',align:'center',key:'submitTime'},
    {title:'任务状态',dataIndex:'status',scopedSlots:{customRender: "status"},align:'center',key:'status'},
    {title:'操作',scopedSlots:{customRender: "action"},align:'center'},
]
import moment from 'moment'
export default {
    data(){
        return{
            columns,
            total:0,
            listMap:[],
            searchInfo:{
                submitTime:null
            },
            drawerModal:{
                visible:false,
                title:'验收处理'
            },
            itemInfo:{}
        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    mounted(){
        this.queryAcceptanceList()
    },
    computed:{
        userId(){
            return this.$store.state.userInfo.userId
        }
    },
    methods:{
        //查询验收列表
        queryAcceptanceList(){
            let params = {...this.searchInfo}
            params.current = this.pageNum
            params.submitTime = params.submitTime ? params.submitTime.format('YYYY-MM-DD') : ''
            this.$api.queryAcceptanceList(params).then(res =>{
                if(res.code === 200){
                    this.listMap = res.data || []
                    this.total = this.listMap.length
                    this.listMap.forEach((item,ind) =>{
                        item.key = ind +1
                        item.actualDay = item.actualDay || 0
                    })
                }
            })
        },
        showDrawerModal(list){
            this.drawerModal.visible = true
            this.itemInfo = list
            if(status === 1 || status === 2){
                this.$nextTick(() =>{
                    this.form.setFieldsValue({
                        status:3
                    })
                })
            }
            
        },
        //重置搜索
        reset(){
            this.searchInfo = {
                submitTime:null
            }
            this.pageNum = 1
            this.queryAcceptanceList()
        },
        search(){
            this.pageNum = 1
            this.queryAcceptanceList()
        },
        changePageNum(p){
            this.pageNum = p.current
            this.queryAcceptanceList()
        },
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    formData.id = this.itemInfo.id
                    formData.acceptanceTime = moment().format('YYYY-MM-DD')
                    
                    this.$api.projectAcceptance(formData).then(res =>{
                        if(res.code === 200){
                            this.drawerModal.visible = false
                            this.queryAcceptanceList()
                        }
                    })
                }
            })
        }
    }
}
</script>